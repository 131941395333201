<template>
  <div class="page-wrapper white">
    <div class="page-info psychology-page">
      <el-row class="page-row w150">
        <el-col class="item">
          <div class="inner">
            <div class="label">姓名</div>
            <div class="content">{{ info.name }}</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">角色类型</div>
            <div class="content">{{ info.role_type }}</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">账号</div>
            <div class="content">{{ info.username }}</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">校区</div>
            <div class="content">{{ info.school_name }}</div>
          </div>
        </el-col>
        <el-col class="item" v-if="info.memorandum_time_start">
          <div class="inner">
            <div class="label">事件起始时间</div>
            <div class="content">{{ info.memorandum_time_start }} - {{ info.memorandum_time_end }}</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">创建时间</div>
            <div class="content">{{ info.created_at }}</div>
          </div>
        </el-col>
        <el-col class="item" v-if="type===0">
          <div class="inner">
            <div class="label">标题</div>
            <div class="content">{{ info.memorandum_title }}</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">备忘内容</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="content">{{ info.memorandum_text }}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="page-tool pt-4 pl-3">
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template><script>
import { getApi2 } from '@/api'
export default {
	_config:{"route":{"path":"details","meta":{"title":"查看"}}},
  name: 'Detail',
  data() {
    return {
      id: 0,
      type: 0,
      info: {
        class_name: '消费方式大哥'
      }
    }
  },
  created() {
    this.id = +this.$route.query.id
    const obj = {
      '/settings/usage-record/teacher/show': 1,
      '/settings/usage-record/student/show': 0
    }
    this.type = obj[this.$route.path] || 0
    this.getDetail()
  },
  methods: {
    getDetail() {
      getApi2(`/memorandum/index/${this.type ? 'teacher' : 'student'}-detail`, { id: this.id }).then(res => {
        this.info = res
      })
    }
  }
}
</script>
